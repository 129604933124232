import React from 'react';


const EnhancedGradientPage = () => {
  
  return (
    
    // <div className="min-h-screen bg-gradient-radial from-[#172026] via-[#5FCDD9] via-[#027373] via-[#04BFAD] to-[#04BF9D] text-white">
    <div className="min-h-screen bg-gradient-radial from-[#172026] via-[#5FCDD9] via-[#027373] via-[#04BFAD] to-[#04BF9D] flex items-center justify-center">
      <header className="w-full bg-white text-gray-800 flex justify-center items-center p-4 shadow-md sticky top-0 z-50">
        <div className="flex items-center space-x-4">
          <img src="logo.png" alt="Logo" className="h-8 mr-4" />
          <nav className="flex space-x-4">
            <a href="#" className="hover:underline">Home</a>
            <a href="#" className="hover:underline">Products</a>
            <a href="#" className="hover:underline">About</a>
            <a href="#" className="hover:underline">Contact</a>
          </nav>
        </div>
      </header>

      <main className="flex flex-col items-center w-full p-8">
        <h1 className="text-4xl font-light mb-6">Welcome to Our Site</h1>

        <div className="w-full max-w-md mb-8">
          <input 
            type="text" 
            className="w-full p-4 text-gray-800 rounded-lg shadow-md" 
            placeholder="Search" 
          />
          <div className="flex justify-between mt-4">
            <button className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow">
              Search
            </button>
            <button className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg shadow">
              I'm Feeling Lucky
            </button>
          </div>
        </div>

        <div className="w-full max-w-4xl overflow-x-auto">
          <div className="flex space-x-4">
            <div className="flex-shrink-0 w-64 h-48 bg-gray-800 text-center text-white flex items-center justify-center">
              Item 1
            </div>
            <div className="flex-shrink-0 w-64 h-48 bg-gray-800 text-center text-white flex items-center justify-center">
              Item 2
            </div>
            <div className="flex-shrink-0 w-64 h-48 bg-gray-800 text-center text-white flex items-center justify-center">
              Item 3
            </div>
            <div className="flex-shrink-0 w-64 h-48 bg-gray-800 text-center text-white flex items-center justify-center">
              Item 4
            </div>
            <div className="flex-shrink-0 w-64 h-48 bg-gray-800 text-center text-white flex items-center justify-center">
              Item 5
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default EnhancedGradientPage;


/**
 * 
 * #172026, #5FCDD9, #027373, #04BFAD, #04BF9D
 * 
 * 
 */