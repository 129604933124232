import React, { useEffect, useState } from 'react';
// import {Header} from './components/Header';
import GradientSearchPage from './components/GradientPage'


const Header = () => {
  return (
    <header className="bg-white shadow-md p-4">
      <h1 className="text-xl font-bold">My Cool Webpage</h1>
    </header>
  );
};


const App = () => {


  const colors = ['#FF5733', '#33FF57', '#3357FF']; // Example colors from Adobe Color
  const gradient = `linear-gradient(to right, ${colors.join(', ')})`;
  const [background, setBackground] = useState('');
  

  // useEffect(() => {

  //   const generateRandomColor = () => {
  //     const randomColor1 = Math.floor(Math.random()*16777215).toString(16);
  //     const randomColor2 = Math.floor(Math.random()*16777215).toString(16);
  //     setBackground(`linear-gradient(to right, #${randomColor1}, #${randomColor2})`);
  //   };

  //   generateRandomColor();
  //   const interval = setInterval(generateRandomColor, 5000); // Change color every 5 seconds
  //   return () => clearInterval(interval);

  // }, []);
  
  return (
    <GradientSearchPage/>
    // <div style={{ background: gradient }} className="min-h-screen flex flex-col">
    //   <Header />
    //   <main className="flex-grow flex items-center justify-center">
    //     <div className="flex flex-col items-center">
    //       <input 
    //         type="text" 
    //         className="border border-gray-300 rounded-full p-4 w-80" 
    //         placeholder="Search Google or type a URL" 
    //       />
    //       <button className="mt-4 bg-blue-500 text-white rounded-full px-6 py-2 hover:bg-blue-600">
    //         Google Search
    //       </button>
    //     </div>
    //   </main>
    // </div>
  );
};

export default App;